<template lang="pug">
om-table.brand-full-table.affiliate-item-table(
  :items="tableItems"
  :columns="columns"
  :columnClasses="columnClasses"
  :allItemsCount="tableItems.length"
  :selectable="false"
  :show-header="true"
  :paginate="false"
  :sorting="sort"
  @sortingChange="sortChange"
)
  template(slot="date_added" slot-scope="{ rowData }")
    span {{ formatDate(rowData.row.date_added, customDateFormat) }}

  template(slot="order_total" slot-scope="{ rowData }")
    template(v-if="isOrderTypeItem(rowData.row)")
      span {{ rowData.row.formattedOrderTotal }}
    template(v-else)
      span -

  template(slot="total" slot-scope="{ rowData }")
    span {{ rowData.row.formattedTotal }}

  template(slot="status" slot-scope="{ rowData }")
    template(v-if="rowData.row.status === 2")
      span(style="color: red") {{ $t('ambassadorPayoutRequestPage.item.status.invalid') }}
    template(v-else-if="rowData.row.status === 1")
      span(style="color: green") {{ $t('ambassadorPayoutRequestPage.item.status.valid') }}
    template(v-else)
      span -
</template>

<script>
  import dateFormat from '@/mixins/dateFormat';
  import affiliateEnums from '@/mixins/affiliateEnums';

  export default {
    mixins: [dateFormat, affiliateEnums],

    props: {
      items: { type: Array, require: true },
      sortable: { type: Boolean, default: false },
      customDateFormat: { type: String },
    },

    data() {
      return {
        columnClasses: {
          date_added: 'text-left',
          denomination: 'text-left',
          type: 'text-left',
          order_total: 'text-right',
          total: 'text-right',
        },
        sort: {
          field: 'date_added',
          direction: -1,
        },
      };
    },

    computed: {
      tableItems() {
        return this.items.map((item) => {
          let type = item.type;
          if (item.type === 'modify') {
            type = this.$t('ambassadorPayoutRequestPage.item.modify');
            item.denomination = type;
            return item;
          }
          if (item.type === 'order') {
            type = this.$t('ambassadorPayoutRequestPage.item.orderName', {
              email: item.source_email,
            });
            item.denomination = type;
            return item;
          }
          if (item.type === 'refund') {
            type = this.$t('ambassadorPayoutRequestPage.item.refundName', {
              email: item.source_email,
            });
            item.denomination = type;
            return item;
          }
          if (item.type === 'migration') {
            type = this.$t('ambassadorPayoutRequestPage.item.migration');
            item.denomination = type;
            return item;
          }
          return item;
        });
      },
      columns() {
        return [
          {
            header: this.$t('ambassadorPayoutRequestPage.head.added'),
            key: 'date_added',
            sortable: this.sortable,
          },
          {
            header: this.$t('ambassadorPayoutRequestPage.head.name'),
            key: 'denomination',
            sortable: this.sortable,
          },
          {
            header: this.$t('ambassadorPayoutRequestPage.head.orderTotal'),
            key: 'order_total',
            sortable: this.sortable,
          },
          {
            header: this.$t('ambassadorPayoutRequestPage.head.commission'),
            key: 'total',
            sortable: this.sortable,
          },
        ];
      },
    },

    methods: {
      sortChange($event) {
        this.tableItems.sort((a, b) => {
          const result =
            $event.direction === 1
              ? a[$event.field] > b[$event.field]
              : a[$event.field] < b[$event.field];
          return result ? 1 : -1;
        });
      },
    },
  };
</script>

<style lang="sass" scoped>
  .affiliate-item-table
    ::v-deep.brand-table
      .thead
        .tr
          .text-left
            display: flex
            justify-content: flex-start
            text-align: left !important
          .text-right
            display: flex
            justify-content: flex-end !important

    ::v-deep.brand-table
      .tbody
        .brand-table-tr
          padding: 15px 0
          margin: 0
          min-height: 0

          .td
            font-weight: 400
</style>
