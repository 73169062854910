var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('om-table',{staticClass:"brand-full-table affiliate-item-table",attrs:{"items":_vm.tableItems,"columns":_vm.columns,"columnClasses":_vm.columnClasses,"allItemsCount":_vm.tableItems.length,"selectable":false,"show-header":true,"paginate":false,"sorting":_vm.sort},on:{"sortingChange":_vm.sortChange},scopedSlots:_vm._u([{key:"date_added",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(rowData.row.date_added, _vm.customDateFormat)))])]}},{key:"order_total",fn:function(ref){
var rowData = ref.rowData;
return [(_vm.isOrderTypeItem(rowData.row))?[_c('span',[_vm._v(_vm._s(rowData.row.formattedOrderTotal))])]:[_c('span',[_vm._v("-")])]]}},{key:"total",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(_vm._s(rowData.row.formattedTotal))])]}},{key:"status",fn:function(ref){
var rowData = ref.rowData;
return [(rowData.row.status === 2)?[_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.$t('ambassadorPayoutRequestPage.item.status.invalid')))])]:(rowData.row.status === 1)?[_c('span',{staticStyle:{"color":"green"}},[_vm._v(_vm._s(_vm.$t('ambassadorPayoutRequestPage.item.status.valid')))])]:[_c('span',[_vm._v("-")])]]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }