import { mapGetters } from 'vuex';
import GET_SALES_AUTH from '@/graphql/GetSalesAuth.gql';
import { PaymentAdapter } from '@/services/paymentAdapter';

export default {
  data() {
    return {
      PaymentAdapter: null,
      affiliate: {
        commissions: [
          {
            total: '0%',
          },
        ],
        main: {
          referred_visitors: 0,
          month_referred_visitors: 0,
          week_referred_visitors: 0,
          referred_subscribers: 0,
          month_referred_subscribers: 0,
          week_referred_subscribers: 0,
          month_referred_customers: 0,
          week_referred_customers: 0,
          referred_commissions: 0,
          month_referred_commissions: 0,
          week_referred_commissions: 0,
          total_all_payout: '$ 0',
          total_actual_do_payout: '$ 0',
          month_can_payout: '$ 0',
          week_can_payout: '$ 0',
          total_can_payout: '$ 0',
          minimum_payout: '$ 0',
          enable_new_payout: false,
          year_referred_subscribers: 0,
          year_referred_customers: 0,
          year_referred_visitors: 0,
          year_can_payout: '$ 0',
          lastmonth_referred_subscribers: 0,
          lastmonth_referred_customers: 0,
          lastmonth_referred_visitors: 0,
          lastmonth_can_payout: '$ 0',
          lastyear_referred_subscribers: 0,
          lastyear_referred_customers: 0,
          lastyear_referred_visitors: 0,
          lastyear_can_payout: '$ 0',
        },
        partner_data: {
          aname: '',
          email: '',
          postcode: '',
          city: '',
          address: '',
        },
        tools: [
          {
            link: '',
          },
        ],
      },
      payoutItems: [],
    };
  },

  computed: {
    ...mapGetters(['accountFeatures']),
  },

  methods: {
    async getPaymentAdapter() {
      if (this.paymentAdapter) return this.PaymentAdapter;
      const {
        data: { salesAuth },
      } = await this.$apollo.query({ query: GET_SALES_AUTH });
      const adapter = new PaymentAdapter(salesAuth, this.accountFeatures, this.$apollo);
      this.$set(this, 'PaymentAdapter', adapter);
      return adapter;
    },
    async fetchAffiliateData() {
      const paymentAdapter = await this.getPaymentAdapter();
      const { data } = await paymentAdapter.getAffiliateData();
      return data;
    },
    async setAffiliateData() {
      const data = await this.fetchAffiliateData();
      this.$set(this, 'affiliate', data);
    },
    async fetchPayoutItems() {
      const paymentAdapter = await this.getPaymentAdapter();
      return paymentAdapter.getAffiliatePayoutItems();
    },
    async setPayoutItems() {
      const { data } = await this.fetchPayoutItems();
      const items = data?.items ?? [];
      this.$set(this, 'payoutItems', items);
    },
    async sendPayout() {
      const paymentAdapter = await this.getPaymentAdapter();
      return paymentAdapter.sendPayout();
    },
  },
};
